<template>
  <div class="popup-wrapper" @click="backdropClick($event)">
    <div class='hauberk-popup' :style="popupStyle" @click="popupClicked($event)">
      <div class="hauberk-popup__title">
        <slot name="title"></slot>
      </div>
      <div class="hauberk-popup__body">
        <slot name="body"></slot>
      </div>
      <div class="hauberk-popup__footer d-flex align-items-center">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HauberkPopup',
  props: {
    popupStyle: {
      top: String,
      left: String,
      width: String
    }
  },
  data() {
    return {}
  },
  methods: {
    backdropClick(event) {
      event.stopPropagation();

      this.$emit('backdrop-clicked');
    },
    popupClicked(event) {
      event.stopPropagation();
    }
  }
}
</script>

<style lang='scss' scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.2);

  .hauberk-popup {
    display: flex;
    flex-direction: column;
    min-width: 300px;

    position: relative;

    border-radius: 5px;
    background: rgb(255, 255, 255);

    &__title {
      padding: 10px 20px;
      border-bottom: 1px solid #dedede;
      user-select: none;
    }

    &__body {
      padding: 10px 20px;
      min-height: 100px;
    }

    &__footer {
      display: flex;
      flex-direction: row-reverse;
      padding: 10px 20px;
      border-top: 1px solid #dedede;
    }
  }
}
</style>