<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div>
    <CModal
      :title="$t('network.networkParameters')"
      :show="isShowModal"
      :closeOnBackdrop="false"
      class="hide-close-button d-flex flex-column align-items-center"
      size="lg"
    >
      <div class="hauberk-form-group" v-if="shouldSelectAdmin">
        <div class="hauberk-form-group__title">
          {{ $t("network.commomProperties") }}
        </div>

        <div class="hauberk-form-item hb-item d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="networkAdmin">
              {{ $t("network.admin") }}
            </label>
            <span class="hb-label__subtitle">network_administrator_org_id</span>
          </div>

          <CSelect
            class="hb-item__input"
            id="networkAdmin"
            :options="providers"
            :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }"
            :isValid="isValid.networkAdmin"
            :tooltipFeedback="false"
            @change="$v.form.networkAdmin.$touch()"
            :value.sync="form.networkAdmin"
            required
            placeholder=" "
          />

          <div
            class="info-sign ml-2"
            v-c-tooltip="{ content: $t('network.parameters.admin') }"
          >
            <span class="info-sign__letter">i</span>
          </div>
        </div>
      </div>

      <div class="hauberk-form-group">
        <div class="hauberk-form-group__title" v-if="shouldSelectAdmin">
          {{ $t("network.ordererParameters") }}
        </div>

        <div class="hauberk-form-item hb-item d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="batchTimeout">{{
              $t("network.batchTimeout")
            }}</label>
            <span class="hb-label__subtitle">batch_timeout</span>
          </div>
          <CInput
            class="hb-item__input"
            id="batchTimeout"
            :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }"
            :isValid="isValid.batchTimeout"
            :tooltipFeedback="false"
            v-model="form.batchTimeout"
            @change="$v.form.batchTimeout.$touch()"
          />

          <div
            class="info-sign ml-2"
            v-c-tooltip="{ content: $t('network.parameters.batchTimeout') }"
          >
            <span class="info-sign__letter">i</span>
          </div>
        </div>

        <div class="hauberk-form-item hb-item d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="maxMessageCount">{{
              $t("network.maxMessagesCount")
            }}</label>
            <span class="hb-label__subtitle">max_message_count</span>
          </div>

          <CInput
            id="maxMessageCount"
            class="hb-item__input"
            :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }"
            :isValid="isValid.maxMessageCount"
            :tooltipFeedback="false"
            addLabelClasses="font-weight-bold"
            v-model="form.maxMessageCount"
            @change="$v.form.maxMessageCount.$touch()"
          />

          <div
            class="info-sign ml-2"
            v-c-tooltip="{ content: $t('network.parameters.maxMessageCount') }"
          >
            <span class="info-sign__letter">i</span>
          </div>
        </div>

        <div class="hauberk-form-item hb-item d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="absoluteMaxBytes">{{
              $t("network.absoluteMaxBytes")
            }}</label>
            <span class="hb-label__subtitle">absolute_max_bytes</span>
          </div>

          <CInput
            class="hb-item__input"
            id="absoluteMaxBytes"
            :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }"
            :isValid="isValid.absoluteMaxBytes"
            :tooltipFeedback="false"
            addLabelClasses="font-weight-bold"
            v-model="form.absoluteMaxBytes"
            @change="$v.form.absoluteMaxBytes.$touch()"
          />

          <div
            class="info-sign ml-2"
            v-c-tooltip="{ content: $t('network.parameters.absoluteMaxBytes') }"
          >
            <span class="info-sign__letter">i</span>
          </div>
        </div>

        <div class="hauberk-form-item hb-item d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="preferedMaxBytes">{{
              $t("network.preferedMaxBytes")
            }}</label>
            <span class="hb-label__subtitle">preferred_max_bytes</span>
          </div>

          <CInput
            id="preferedMaxBytes"
            class="hb-item__input"
            :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }"
            :isValid="isValid.preferredMaxBytes"
            :tooltipFeedback="false"
            addLabelClasses="font-weight-bold"
            v-model="form.preferredMaxBytes"
            @change="$v.form.preferredMaxBytes.$touch()"
          />

          <div
            class="info-sign ml-2"
            v-c-tooltip="{
              content: $t('network.parameters.preferredMaxBytes'),
            }"
          >
            <span class="info-sign__letter">i</span>
          </div>
        </div>
      </div>

      <div slot="footer">
        <CButton
          type="button"
          class="btn btn-secondary mr-2"
          @click="closeModal"
        >
          {{ $t("common.cancel") }}
        </CButton>
        <CButton
          type="button"
          class="btn btn-primary"
          :disabled="!isFormValid"
          @click="confirm"
        >
          {{ $t("common.continue") }}
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import { required, requiredIf, alphaNum } from "vuelidate/lib/validators";

import messages from "../messages";
import { BlockchainConfigUpdateType } from "./constants";

const _formDefaultValues = {
  networkAdmin: null,
  batchTimeout: "2s",
  maxMessageCount: "10",
  absoluteMaxBytes: "99MB",
  preferredMaxBytes: "512KB",
};

export default {
  name: "NetworkOrdererParameters",
  props: {
    isShowModal: Boolean,
    providers: Array,
    blockchainUpdatePolicyType: String,
  },
  mounted() {
    this.setDefaultValues();
  },
  data() {
    return {
      validationMessages: messages.validation,
      form: {
        networkAdmin: null,
        batchTimeout: "2s",
        maxMessageCount: "10",
        absoluteMaxBytes: "99MB",
        preferredMaxBytes: "512KB",
      },
    };
  },
  validations: {
    form: {
      networkAdmin: {
        required: requiredIf(function () {
          return this.hasMultipleProviders;
        }),
      },
      batchTimeout: { required, alphaNum },
      maxMessageCount: { required, alphaNum },
      absoluteMaxBytes: { required, alphaNum },
      preferredMaxBytes: { required, alphaNum },
    },
  },
  computed: {
    isValid() {
      let networkAdmin,
        batchTimeout,
        maxMessageCount,
        absoluteMaxBytes,
        preferredMaxBytes,
        opAdmin;

      if (this.$v.form.networkAdmin.$dirty) {
        networkAdmin = !this.$v.form.networkAdmin.$invalid;
      }

      if (this.$v.form.batchTimeout.$dirty) {
        batchTimeout = !this.$v.form.batchTimeout.$invalid;
      }

      if (this.$v.form.maxMessageCount.$dirty) {
        maxMessageCount = !this.$v.form.maxMessageCount.$invalid;
      }

      if (this.$v.form.absoluteMaxBytes.$dirty) {
        absoluteMaxBytes = !this.$v.form.absoluteMaxBytes.$invalid;
      }

      if (this.$v.form.preferredMaxBytes.$dirty) {
        preferredMaxBytes = !this.$v.form.preferredMaxBytes.$invalid;
      }

      return {
        networkAdmin,
        batchTimeout,
        maxMessageCount,
        absoluteMaxBytes,
        preferredMaxBytes,
      };
    },
    isFormValid() {
      return !this.$v.form.$invalid;
    },
    hasMultipleProviders() {
      return this.providers.length && this.providers.length > 1;
    },
    shouldSelectAdmin() {
      const updateTypes = [
        BlockchainConfigUpdateType.OR.code,
        BlockchainConfigUpdateType.MAJORITY.code,
      ];

      return !updateTypes.includes(this.blockchainUpdatePolicyType);
    },
  },
  methods: {
    closeModal() {
      this.$v.$reset();
      this.setDefaultValues();
      this.$emit("close");
    },
    confirm() {
      const networkParameters = {
        network_administrator_org_id: this.form.networkAdmin,
        orderer_config: {
          batch_timeout: this.form.batchTimeout,
          max_message_count: this.form.maxMessageCount,
          absolute_max_bytes: this.form.absoluteMaxBytes,
          preferred_max_bytes: this.form.preferredMaxBytes,
        },
      };

      this.$emit("confirm", networkParameters);
      this.closeModal();
    },
    setDefaultValues() {
      const deafultValues = Object.assign({}, { ..._formDefaultValues });

      if (
        this.blockchainUpdatePolicyType ===
        BlockchainConfigUpdateType.EXCLUSIVE.code
      ) {
        const defaultProvider = this.providers.find((item) => item.selected);
        deafultValues.networkAdmin = defaultProvider?.value;
      }

      this.form = deafultValues;
      this.$v.$touch();
    },
  },
};
</script>
