<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CForm>
        <CCard v-if="network">
          <CCardHeader>
            <strong>
              {{ $t("network.item") }} {{ network.name }}
              <CBadge :color="getBadge(network.update_status)">
                {{ getBadgeLabel(network.update_status) }}
              </CBadge>
            </strong>
          </CCardHeader>
          <CCardBody>
            <template>
              <CInput
                :label="$t('network.tableName')"
                horizontal
                addLabelClasses="font-weight-bold"
                v-model="network.name"
                :plaintext="true"
                required
              />

              <CInput
                :label="$t('network.programId')"
                horizontal
                addLabelClasses="font-weight-bold"
                v-model="network.code_name"
                :plaintext="true"
                required
              />

              <CInput
                :label="$t('network.type')"
                horizontal
                addLabelClasses="font-weight-bold"
                :value="networkTypeLabel[network.version]"
                :plaintext="true"
                required
              />

              <CInput
                :label="$t('network.netPolicy')"
                horizontal
                addLabelClasses="font-weight-bold"
                v-if="policy[network.blockchain_update_policy_type]"
                :value="policy[network.blockchain_update_policy_type].text"
                :plaintext="true"
                required
              />
            </template>

            <template>
              <FormObjectsCollapse
                :label="$t('network.osnProviders')"
                addLabelClasses="font-weight-bold"
                :addButtonLabel="$t('network.addProvider')"
                :itemNameAttr="['name', 'mspid']"
                :itemNewName="$t('network.provider')"
                :ordererOrganizations="network.orderer_organizations"
                :orderers="network.orderers"
                objectType="organization_orderer"
                class="mb-4"
              />

              <FormObjectsCollapse
                :label="$t('network.participants')"
                addLabelClasses="font-weight-bold"
                :addButtonLabel="$t('network.addParty')"
                :itemNameAttr="['name', 'mspid']"
                :itemNewName="$t('network.party')"
                :consortium="network.consortium"
                objectType="organization_peer"
                class="mb-4"
              />
            </template>
          </CCardBody>
          <CCardFooter>
            <template v-if="network && networkOld && !isEditingMode">
              <div
                class="tooltip-wrapper"
                v-if="renderComponent"
                v-c-tooltip="{
                  content: tooltipPublish,
                  placement: 'top',
                  html: true,
                }"
              >
                <CButton
                  type="submit"
                  color="success"
                  class="mr-4"
                  :disabled="!canPublish"
                  @click.stop.prevent="createNetwork()"
                >
                  <CIcon name="cil-check-circle" />
                  {{ $t("network.createNetwork") }}
                </CButton>
              </div>

              <div
                class="tooltip-wrapper"
                v-if="renderComponent"
                v-c-tooltip="{
                  content: tooltipSave,
                  placement: 'top',
                  html: true,
                }"
              >
                <CButton
                  color="primary"
                  class="mr-4"
                  @click.stop.prevent="saveDraft()"
                  :disabled="!canSave"
                >
                  <CIcon name="cil-pencil" />
                  {{ $t("network.saveDraft") }}
                </CButton>
              </div>

              <CLink
                v-if="isNotChanged"
                class="text-danger"
                @click.stop.prevent="deleteDraft()"
                >{{ $t("network.deleteDraft") }}
              </CLink>
              <CLink v-else class="text-danger" @click="cancelDraft()"
                >{{ $t("network.cancelDraft") }}
              </CLink>
            </template>

            <template v-if="isEditingMode">
              <CButton
                type="submit"
                color="success"
                class="mr-4"
                :disabled="!canPublish"
                @click.stop.prevent="requestTransationParameters()"
              >
                <CIcon name="cil-check-circle" />
                {{ $t("network.changeNetwork") }}
              </CButton>
              <CButton
                color="primary"
                class="mr-4"
                @click.stop.prevent="saveDraft()"
                :disabled="!canSave"
              >
                <CIcon name="cil-pencil" />
                {{ $t("network.saveDraft") }}
              </CButton>
              <CLink class="text-danger" @click.stop.prevent="cancelChanging()"
                >{{ $t("network.cancellChanging") }}
              </CLink>
            </template>
          </CCardFooter>
        </CCard>
      </CForm>
      <ModalParam
        :isShowModal="isShowModal"
        :ordererList="modalParam.ordererList"
        :fabricAdminToolList="modalParam.fabricAdminToolList"
        @closeModal="isShowModal = false"
        @createParam="saveNetworkChanges"
      />

      <network-orderer-parameters
        v-if="!!network"
        :isShowModal="showAdminSelectionPopup"
        :providers="ordererOrganizations"
        :blockchainUpdatePolicyType="network.blockchain_update_policy_type"
        @confirm="publishNetwork"
        @close="closeModal"
      >
      </network-orderer-parameters>
    </CCol>
  </CRow>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import utilMessages from "../../../utils/messages";
import { sameObjects } from "../../../utils/system";
import { CertificateTypes } from "../certificates/constants";
import FormObjectsCollapse from "../components/FormObjectsCollapse.vue";
import {
BlockchainConfigUpdateType,
NetworkTypes,
NetworkUpdateStatus
} from "./constants";
import ModalParam from "./ModalParam.vue";

import HauberkPopup from "../common/HauberkPopup";
import NetworkOrdererParameters from "./NetworkParameters";

export default {
  name: "NetworkDraft",
  components: {
    FormObjectsCollapse,
    ModalParam,
    HauberkPopup,
    NetworkOrdererParameters,
  },
  data() {
    const networkStacks = [
      { value: "hlf", label: "Hyperledger Fabric" },
      { value: "corda", label: "Corda" },
      { value: "eth", label: "Ethereum" },
    ];
    return {
      renderComponent: true,
      network_stacks: networkStacks,
      policy: BlockchainConfigUpdateType,
      netTypes: NetworkTypes,
      certTypes: CertificateTypes,
      netUpdateStatus: NetworkUpdateStatus,

      networkOld: null,
      network: null,
      network_status: null,
      network_stack: null,
      isShowModal: false,
      modalParam: {
        ordererList: [],
        fabricAdminToolList: [],
      },
      selectedNetworkAdmin: null,
      showAdminSelectionPopup: false,
      popupStyle: {
        top: "",
        left: "",
        width: "",
      },
    };
  },
  watch: {
    tooltipSave(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.forceRerender();
      }
    },
    tooltipPublish(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.forceRerender();
      }
    },
  },
  async mounted() {
    if (!this.$route.params.id) {
      return;
    }

    const netId = this.$route.params.id;

    if (
      !this.isEditingMode &&
      this.getUser.blockchain_net_update_status ===
        this.netUpdateStatus.success.code
    ) {
      this.$router.push({ path: `/networks/${netId}` });
    }

    await this.loadData();
    await this.getNetwork();
    this.fillNetworkOld();
  },
  computed: {
    ...mapGetters([
      "orgAdminCertsIsChanged",
      "getDraftNetworkOrgIds",
      "getCorrectOrganizationIds",
      "isAdmin",
      "getUser",
    ]),
    canPublish() {
      return (
        this.hasCorrectOrderers &&
        this.hasCorrectOrdererOrganizations &&
        this.hasCorrectConsortium
      );
    },
    hasCorrectOrderers() {
      return (
        !!this.network &&
        !!this.network.orderers.length &&
        !!this.network.orderers[0].id
      );
    },
    hasCorrectOrdererOrganizations() {
      return (
        !!this.network &&
        !!this.network.orderer_organizations.length &&
        !!this.network.orderer_organizations[0].id
      );
    },
    hasCorrectConsortium() {
      return (
        !!this.network &&
        !!this.network.consortium.length &&
        !!this.network.consortium[0].id
      );
    },
    canSave() {
      return (
        !this.isNotChanged &&
        this.hasCorrectOrderers &&
        this.hasCorrectOrdererOrganizations &&
        this.hasCorrectConsortium
      );
    },
    isNotChanged() {
      return (
        this.network &&
        this.networkOld &&
        sameObjects("consortium", this.network, this.networkOld) &&
        sameObjects("orderer_organizations", this.network, this.networkOld) &&
        sameObjects("orderers", this.network, this.networkOld) &&
        !this.orgAdminCertsIsChanged
      );
    },
    networkTypeLabel() {
      let result = {};
      result[this.netTypes.HLF14.code] = this.netTypes.HLF14.text;
      result[this.netTypes.HLF20.code] = this.netTypes.HLF20.text;
      result[this.netTypes.HLF22.code] = this.netTypes.HLF22.text;
      result[this.netTypes.GOSMART10.code] = this.netTypes.GOSMART10.text;
      return result;
    },
    tooltipSave() {
      let message = "";

      if (!this.canSave) {
        if (this.isNotChanged) {
          message = this.$i18n.t("network.noChanges") + "<br/>";
        }

        if (
          !(
            this.hasCorrectOrdererOrganizations &&
            this.hasCorrectOrderers &&
            this.hasCorrectConsortium
          )
        )
          message += this.$i18n.t("network.msg[0]") + "<br/>";

        if (!this.hasCorrectOrdererOrganizations)
          message += this.$i18n.t("network.msg[1]") + "<br/>";

        if (!this.hasCorrectOrderers)
          message += this.$i18n.t("network.msg[2]") + "<br/>";

        if (!this.hasCorrectConsortium)
          message += this.$i18n.t("network.msg[3]") + "<br/>";
      }

      return !message ? this.$i18n.t("network.msg[5]") : message;
    },
    tooltipPublish() {
      let message = "";

      if (!this.canPublish) {
        message = this.$i18n.t("network.publishMsg[0]") + "<br/>";

        if (!this.hasCorrectOrdererOrganizations)
          message += this.$i18n.t("network.publishMsg[1]") + "<br/>";

        if (!this.hasCorrectOrderers)
          message += this.$i18n.t("network.publishMsg[2]") + "<br/>";

        if (!this.hasCorrectConsortium)
          message += this.$i18n.t("network.publishMsg[3]") + "<br/>";
      }

      if (!message) {
        message = this.canSave
          ? this.$i18n.t("network.publishMsg[5]") + "<br/>"
          : this.$i18n.t("network.publishMsg[6]") + "<br/>";
      }

      return message;
    },
    isEditingMode() {
      return this.$route.path.includes("edit");
    },
    ordererOrganizations() {
      if (!this.network || !this.network.orderer_organizations) {
        return [];
      }

      return this.network.orderer_organizations.map((item) => ({
        label: item.name,
        value: item.id,
        ...(this.getUser.org_id === item.id && { selected: true }),
      }));
    },
  },
  methods: {
    ...mapActions(["fetchEndpoints"]),
    async loadData() {
      let requestData = {};

      if (!this.isAdmin) requestData.view = "blockchain";

      try {
        await this.$store.dispatch("fetchOrganizations", requestData);
        await this.$store.dispatch("fetchEndpoints");
        await this.$store.dispatch("fetchCertificates");
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getNetwork() {
      if (this.$route.params.id) {
        const netId = this.$route.params.id;

        try {
          await this.$store.dispatch("fetchDraftNetwork", netId);
          this.network = this.$store.getters.selectedDraftNetwork;
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }
    },
    checkCertificates() {
      let errors = [];
      for (let org of this.network.orderer_organizations) {
        if (!org.msp.cacerts.length || !org.msp.tlscacerts.length) {
          const startedStr = this.$i18n.t("network.inOrg");
          const necessary = this.$i18n.t("network.necessary");
          errors.push(`${startedStr} ${org.mspid} ${necessary} `);
        }
      }
      if (errors.length) {
        throw new Error(errors.join("\n"));
      }
    },
    async createNetwork() {
      if (this.network.orderer_organizations.length < 1) {
        return;
      }

      this.showAdminSelectionPopup = true;
    },
    async publishNetwork(networkParameters) {
      await this.saveDraft();

      try {
        this.checkCertificates();
      } catch (err) {
        this.$toast.error(err);
        return;
      }

      try {
        const res = await this.$store.dispatch("publishDraftNetwork", {
          netId: this.network.id,
          data: networkParameters,
        });
        this.$router.push({ path: `/topics/${res.topic_id}` });
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async saveDraft() {
      try {
        await this.$store.dispatch("saveDraftNetwork", {
          netId: this.network.id,
          data: this.network,
        });

        this.$toast.success(this.$i18n.t("network.draftSaved"));

        await this.getNetwork();
        this.fillNetworkOld();
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async deleteDraft() {
      try {
        await this.$store.dispatch("deleteNetwork", this.network.id);
        this.$toast.success(this.$i18n.t("network.draftRemoved"));
        this.$store.dispatch("fetchUserOrganizations");
        this.$store.dispatch("switchOrganization"); // return to admin org
        this.$router.push({ path: `/networks` });
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    getBadge(status) {
      switch (status) {
        case "active":
          return "success";
        case "draft":
          return "primary";
      }
    },
    getBadgeLabel(status) {
      switch (status) {
        case "active":
          return this.$t("common.active");
        case "draft":
          return this.$t("common.draft");
      }
    },
    async cancelDraft() {
      await this.getNetwork();
    },
    fillNetworkOld() {
      this.networkOld = {
        orderer_organizations: [...this.network.orderer_organizations],
        consortium: [...this.network.consortium],
        orderers: [...this.network.orderers],
      };
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    cancelChanging() {
      this.$router.push(`/networks/${this.$route.params.id}`);
    },
    async requestTransationParameters() {
      try {
        this.modalParam.fabricAdminToolList = await this.fetchEndpoints(
          "status=attached&current_org=true&type=fabric-admin-tools"
        );

        this.modalParam.ordererList = await this.fetchEndpoints(
          `blockchain_net_id=${this.network.id}&type=orderer`
        );

        this.isShowModal = true;
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async saveNetworkChanges(data) {
      try {
        await this.$store.dispatch("saveDraftNetwork", {
          netId: this.network.id,
          data: this.network,
        });
        const response = await this.$store.dispatch("createUpdateTransaction", {
          netId: this.network.id,
          data,
        });

        this.$router.push({
          path: `/networks/${this.network.id}/change-config?topic_id=${response.topic_id}`,
        });
      } catch (error) {
        this.$toast.error(utilMessages.errMessage(error));
      }
    },
    closeModal() {
      this.showAdminSelectionPopup = false;
    },
  },
};
</script>

<style scoped>
button svg {
  margin-left: 5px;
  margin-right: 5px;
}

.tooltip-wrapper {
  display: inline-block;
}

.participants-list__label {
  padding: 0;
  margin: 0;
}

.participants-title {
  margin: 0;
  padding: 0;
  font-weight: 700;
}
</style>
